const About = () => {
  return (
    <div
      style={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontFamily: "Helvetica",
        color: "white",
        fontSize: "100%",
        paddingTop: "50px",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <p>
          At Firefly Gaming we're all about creating a rewarding player
          experience whilst meeting and exceeding our clients' expectations.
          <br />
          <br />
          With over 30 years of experience in the iGaming industry, contact us
          today and see how we can help you.
          <br />
          <br />
          <a
            style={{ color: "white" }}
            href="mailto:info@fireflygaming.co.uk?subject=Enquiry"
          >
            info@fireflygaming.co.uk
          </a>
        </p>
      </div>
    </div>
  );
};

export default About;
