import Logo from "./Logo";
import About from "./About";

const Home = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Logo />
      <About />
    </div>
  );
};

export default Home;
