import logo from "../assets/logo.png";

const Logo = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "35%",
        paddingTop: "5vh",
        position: "relative",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        style={{
          position: "relative",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};

export default Logo;
