import "./Particle.scss";
import Home from "./components/Home";
import Particles from "./components/Particles";

function App() {
  return (
    <div
      className="outer-wrap"
      style={{
        width: "100%",
        height: window.navigator.userAgent.includes("iPhone")
          ? window.innerHeight + "px"
          : "100vh",
      }}
    >
      <div className="container">
        <Particles />
        <Home />
      </div>
    </div>
  );
}

export default App;
