import "../Particle.scss";

const Particle = () => {
  return (
    <div className="circle-container">
      <div className="circle"></div>
    </div>
  );
};

const Particles = () => {
  const particles = [];

  for (let i = 0; i < 150; i++) {
    particles.push(<Particle key={`Particle_${i}`} />);
  }
  return particles;
};

export default Particles;
